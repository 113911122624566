<template>
   <div class="container-sm section-default">
    <Message :msg="msg" v-show="msg" />
    <div class="form-card">
      <div class="header">
        <h1>Cadastre sua<br/>empresa na <span class="green-text">SFiT</span></h1>
      </div>
      <form id="contato-form" @submit="sendFormCadastro" class="row g-3 form-login">
        <div class="col-12">
          <label for="email" class="form-label">Email</label>
          <input type="text" class="form-control" id="email" name="email" v-model="email" placeholder="Informe seu email">
        </div>
        <div class="col-12">
          <label for="senha" class="form-label">Senha</label>
          <input type="password" class="form-control" id="senha" name="senha" v-model="senha" placeholder="Crie uma senha">
        </div>
        <div class="col-12">
          <label for="resenha" class="form-label">Confirmar senha</label>
          <input type="password" class="form-control" id="resenha" name="resenha" v-model="resenha" placeholder="Repita sua senha">
        </div>        
        <div class="d-grid col-12 mx-auto">
          <button type="submit" class="btn form-button">Enviar</button>
        </div>
        <div class="form-login_options">
          <span>Ao continuar, você concorda que leu e concorda com<br/>nossos
            <router-link to="/termos" class="rvl-link">Termos de uso</router-link>
            e nossa
            <router-link to="/politica-de-privacidade" class="rvl-link">Política de privacidade</router-link>
          </span>
        </div>
        <div class="form-login_options">
          <span>Você já tem uma conta?
            <router-link to="/login" class="rvl-link">Entrar</router-link>
          </span>
        </div>
      </form>
    </div>
   </div>
</template>

<script>

import Message from '../components/Message.vue'

export default {
  
  name:'Fale',
    components: {
        Message
  },
  data() {
    return {
      nome:null,
      email:null,
      msg: null
    }
  },
  dataRetLogin() {
    return {
        access_token: null,
        email: null,
        expiration_date: null,
        expires_in: null,
        jti: null,
        scope: null,
        token_type: null,
        user_id: null,
    }
  },
  methods: {
    
    async sendFormCadastro(e) {
      e.preventDefault();

      const dataForm = {
        email: this.email,
        password: this.senha,  
        uuid: 'dsfiouweroijlksdfjioweurlkjsdfoiweujlkjsdfoiwujerlkjfdds'
      }

      const dataJson = JSON.stringify(dataForm)

      console.log(dataJson)

      //logar com o usuario:cadastro.user@sfit.io  e senha: 123 
            // btoa() encodebase64
            // atob() decodebase64
      var bytes = btoa("sfit-recruiter-app:[{app-sfit-recruiter-secret}]");

      console.log(bytes)
      console.log('----')
      console.log(atob(bytes))

      const dataLogin = {
        username: 'cadastro.user@sfit.io',
        password: '123', 
        grant_type: 'password'
      }

      const dataAuth = {
        Authorization: 'Basic ' + bytes,
      }

      console.log(dataAuth)

      var formdata = new FormData();
        formdata.append("username", "cadastro.user@sfit.io");
        formdata.append("password", "123");
        formdata.append("grant_type", "password");

      var response = await fetch('http://31.170.165.72:8080/oauth/token',{
        method: "POST",
        headers: dataAuth,
        body: formdata
      });
     
      console.log(response)  
      const dataRetLogin = await response.json();  
      console.log('retLogin='+dataRetLogin.access_token)
      console.log('-------')

      // fim do login

      const dataAuthToken = {
        'Authorization': 'Bearer ' + dataRetLogin.access_token,
        'Content-Type': 'application/json' 
      }

      const req = await fetch('http://31.170.165.72:8080/v1/users',{
        method: "POST",
        headers: dataAuthToken,
        body:dataJson

      });

      console.log('-------')
      console.log(req.json())
      console.log('-------')
      const res = await req.json()

      console.log(res)

      // se receber 200, efetua login com os dados cadastrados
      //mensagem
      this.msg = `Cadastro efetuado com sucesso`

      //apagando mensagem
      setTimeout(()=>this.msg='',3000)


    }
  }
}
</script>


<style scoped>

.form-login {
  width: 450px;
  height: auto;
}

.form-card {
    display: grid;
    place-items: center;
    position: relative;
    text-align: start;
    font: normal normal 400 14px/20px Open Sans;
}

.header {
  padding-right: 120px;
  padding-bottom: 20px;
}

.form-card h1 {
    color: #3256a1;
    font: normal normal 500 36px/44px Antenna;
}

</style>